$font-family-sans-serif: Arial, Helvetica, sans-serif;
$white: #fff;
$blue: #006ba6;
$gray-200: #e9ecef;
$gray-600: #6c757d;
$yiq-text-dark: $gray-600;
$secondary: $gray-600;
$orange: #fd7e14;
$warning: $orange;
$cyan: #80d7ff;
$red: #ed443f;
$green: #38a535;
$light: $gray-200;
$dark: #004062;
$alert-color-level: -10;
$alert-bg-level: 1;
$alert-border-radius: 0;
$alert-border-level: 2;
$alert-margin-bottom: 0;

@import "../node_modules/bootstrap/scss/bootstrap";

a {
    cursor: pointer;
}

label {
    font-weight: bold;
}

.no-select {
    user-select: none;
}

.container > .navbar {
    padding: 0;
}

.navbar-brand {
    padding: 0;
    line-height: 48px;
}

.card-footer {
    min-height: 4em;
}

.card.selectable:hover {
    color: $blue;
    cursor: pointer;
}

.badge {
    font-weight: normal;
}

.badge-warning {
    color: $white;
}

.profile-picture {
    width: 1em;
    height: 1em;
    border-radius: 50%;
}

.btn-warning {
    color: $white;
}
